/* FONTS */
@import "https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Rubik:ital,wght@1,300..900&display=swap";

/* ICONS */
@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";

@import "forms";

/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  turbo-frame {
    display: contents;
  }
}

@layer components {
  s-section, .s-section {
    display: block;
    margin: theme("spacing.16") 0;
  }

  s-container, .s-container {
    display: block;
    margin: 0 auto;
    padding: 0 theme("spacing.6");
    max-width: theme("screens.2xl");
  }

  /* Icon styles */
  .material-symbols-outlined {
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
    user-select: none;
  }
  .material-symbols-outlined.icon-outline {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  }
  .material-symbols-outlined.icon-fill {
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  }
}

@layer utilities {
  .hidden-details > summary {
    list-style: none;
  }
  .hidden-details > summary::-webkit-details-marker {
    display: none;
  }

  /* Icon sizes */
  .icon-xs {
    font-size: 16px;
  }
  .icon-sm {
    font-size: 20px;
  }
  .icon-base {
    font-size: 24px;
  }
  .icon-lg, .icon-xl {
    font-size: 28px;
  }
  .icon-2xl {
    font-size: 32px;
  }
  .icon-3xl {
    font-size: 36px;
  }
  .icon-4xl {
    font-size: 40px;
  }
  .icon-5xl {
    font-size: 48px;
  }
  .icon-6xl {
    font-size: 60px;
  }
  .icon-7xl {
    font-size: 72px;
  }
  .icon-8xl {
    font-size: 96px;
  }
  .icon-9xl {
    font-size: 128px;
  }
}

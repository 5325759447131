@layer components {
  select,
  select[multiple] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48' fill='rgba(255, 255, 255, 1)'%3E%3Cpath d='m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z'/%3E%3C/svg%3E");
    background-position: right theme("spacing.1") center;
    padding-right: 1.6em !important;
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;

    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48' fill='rgba(255, 255, 255, 1)'%3E%3Cpath d='m16.6 33 7.4-7.4 7.4 7.4 1.6-1.6-7.4-7.4 7.4-7.4-1.6-1.6-7.4 7.4-7.4-7.4-1.6 1.6 7.4 7.4-7.4 7.4ZM24 43q-3.9 0-7.375-1.5t-6.05-4.075Q8 34.85 6.5 31.375 5 27.9 5 24q0-3.95 1.5-7.425Q8 13.1 10.575 10.55 13.15 8 16.625 6.5 20.1 5 24 5q3.95 0 7.425 1.5Q34.9 8 37.45 10.55 40 13.1 41.5 16.575 43 20.05 43 24q0 3.9-1.5 7.375t-4.05 6.05Q34.9 40 31.425 41.5 27.95 43 24 43Z'/%3E%3C/svg%3E");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 0.83em;
    width: 0.83em;
  }
}
